.title {
    font-size: clamp(1.4rem, 2.5vw, 2rem);
    font-weight: bold
}

.title2 {
    font-size:clamp(1.2rem, 1.8vw, 1.7rem);
    font-weight: 'bold'
}

.text {
    font-size:clamp(1rem, 1.2vw, 1.4rem)
}

@media (min-width: 992px) {
    .maxWidth { max-width: 500px; }
}

.aboutme u {
    text-decoration: solid underline rgb(0, 70, 246) 2px;
    -webkit-text-decoration-line: underline;
    -webkit-text-decoration-color: rgb(0, 70, 246);
    -webkit-text-decoration-thickness: 2px;
}